<template>
  <div>
    <a-modal
      class="modal-blibli"
      centered
      :visible="showModalForm"
      :wrap-class-name="$style.modalSync"
      :closable="false"
      :width="showSuccessIntegrate ? 400 : 900"
      :footer="false"
    >
      <div class="mb-4 text-center">
        <img :src="require(`@/assets/store/add-store/blibli.svg`)" style="width: 200px; height: auto; margin: 0 auto" />
      </div>
      <a-form-model ref="formBlibli" :model="form" :rules="rules">
        <a-row :gutter="24">
          <a-col :span="8">
            <p style="font-weight: 500">
              {{ $t('add_store.complete_information') }}:
            </p>
            <a-form-model-item prop="id" :label="$t('store_list_page.store_code')">
              <a-input
                v-model="form.id"
                size="large"
                :placeholder="$t('store_list_page.store_code')"
                allow-clear
                :disabled="loadingSync"
              />
            </a-form-model-item>
            <a-form-model-item prop="api" :label="$t('store_list_page.api_password')">
              <a-input
                v-model="form.api"
                size="large"
                :placeholder="$t('store_list_page.api_password')"
                allow-clear
                :disabled="loadingSync"
              />
            </a-form-model-item>
            <a-form-model-item prop="confirm">
              <a-checkbox v-model="form.confirm" size="large" :disabled="loadingSync">
                {{ $t('store_list_page.auto_confirm') }}
              </a-checkbox>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <div style="padding: 15px; background-color: #e9ecef">
              <p class="mb-2" style="font-size: 13px">
                <b>Bagaimana Anda Mendapatkan Nama, ID &amp; Kata Sandi Toko Anda ?</b>
              </p>
              <ol class="mb-4 pl-3 text-left">
                <li class="text-step">
                  Langkah ke 1
                </li>
                <li class="text-desc text-li">
                  Salin API Client ID Powerbiz berikut : 
                  <span v-if="!blibliCode"><b>xxxxxxxxxxxxxxxxxxxxxxxxxx</b></span>
                  <span v-else style="cursor: pointer" @click="handleCopy('Kode', blibliCode)">
                    <b>{{ blibliCode }}</b> 
                    <a-icon type="copy" class="ml-2" />
                  </span>
                  <a-icon v-if="loadingBlibli" type="loading" class="ml-2" />
                </li>
                <li class="text-desc text-li">
                  Masuk ke Seller Center Blibli Anda
                </li>
                <li class="text-desc text-li">
                  Klik menu Seller API Manager pada bagian profile toko Anda
                </li>
                <li class="text-desc text-li">
                  Pada bagian bawah terdapat bagian <b>Bind API Client</b>
                </li>
                <li class="text-desc text-li">
                  Salin Kode <b>API Client ID</b> pada kolom yang tersedia
                </li>
                <li class="text-desc text-li">
                  Lalu klik tombol <b>BIND</b> lalu klik tombol <b>YES</b> jika diminta
                </li>
                <li class="text-step">
                  Langkah ke 2
                </li>
                <li class="text-desc text-li">
                  Pada bagian <b>Manage API Key</b> klik tombol <b>Generate</b>
                </li>
                <li class="text-desc text-li">
                  Catat nilai <b>API Seller Key</b> yang muncul dan <b>Store Code</b> Anda
                </li>
                <li class="text-desc text-li">
                  Salin pada kolom yang telah disediakan di form ini
                </li>
                <li class="text-desc text-li">
                  Langkah selanjutnya klik tombol <b>Mulai Sinkronisasi</b>
                </li>
                <li class="text-desc text-li">
                  Terakhir Anda diminta memilih Gudang Powerbiz dan channel Anda agar terhubung
                </li>
                <li class="text-desc text-li">
                  Jika sudah maka proses integrasi toko Anda telah selesai
                </li>
              </ol>
              <a href="#" style="font-size: 13px; text-decoration: underline; color: #2196F3 !important">
                <b>Klik link berikut untuk melihat video tutorial</b>
              </a>
            </div>
            <div class="mt-4 text-center">
              <a-button
                size="large"
                class="mr-4"
                style="width: 200px"
                :disabled="loadingSync"
                @click="typeof back !== 'undefined' && back(), openModal(null)"
              >
                {{ $t('add_store.back') }}
              </a-button>
              <a-button
                type="primary"
                size="large"
                class="mr-4"
                style="width: 200px"
                :loading="loadingSync"
                @click.prevent="handleAuthorize"
              >
                {{ $t('add_store.start_sync') }}
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="showModalWarehouse"
      :closable="false"
      :width="800"
      :footer="false"
      :title="$t('add_store.sync_warehouse')"
    >
      <a-form-model ref="formWarehouse" :model="formChannel">
        <a-row :gutter="12" class="warehouse-blibli-header">
          <a-col :span="12">
            List Gudang Channel
          </a-col>
          <a-col :span="12">
            List Gudang Powerbiz
          </a-col>
        </a-row>
        <a-row
          v-for="(warehouse, index) in formChannel.warehouses"
          :key="warehouse.pickup_point_id"
          :gutter="16"
          type="flex"
          justify="space-around"
          align="middle"
          class="warehouse-blibli-list"
        >
          <a-col :span="12" style="line-height: 1.5">
            {{ warehouse.name }} - {{ warehouse.display_name }}
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :prop="'warehouses.' + index + '.warehouse_id'"
              :rules="{
                required: true,
                message: 'Gudang Powerbiz harus dipilih',
                trigger: 'change',
              }"
              class="mb-0"
            >
              <a-select
                v-model="warehouse.warehouse_id"
                size="large"
                class="w-100 select-antd-default"
                show-search
                allow-clear
                :options="warehouseList"
                option-filter-prop="children"
                :filter-option="filterOption"
                :placeholder="$t('add_store.select_main_warehouse')"
                :disabled="loadingSync"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="mt-4 text-right">
          <a-button
            size="large"
            class="mr-4"
            style="width: 150px"
            :disabled="loadingSync"
            @click="showModalWarehouse = false, typeof back !== 'undefined' && back(), openModal(null)"
          >
            Batal
          </a-button>
          <a-button
            type="primary"
            size="large"
            style="width: 150px"
            :loading="loadingSync"
            @click.prevent="handleMapWarehouse"
          >
            Submit
          </a-button>
        </div>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="showModalSuccess"
      :closable="false"
      :width="360"
      :footer="false"
    >
      <div class="text-center">
        <img :src="require(`@/assets/store/add-store/success-add.svg`)" style="width:60px !important; height:60px !important;" />
        <h4 class="mt-4">
          Otorisasi Gudang Berhasil!
        </h4>
        <div class="mt-2 w-100 text-center">
          Berhasil menambahkan Gudang Utama dan Gudang Channel.
        </div>
        <a-button
          type="primary"
          size="large"
          class="mt-4 w-100"
          @click.prevent="refreshPage"
        >
          OK
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getWarehouseList } from '@/api/warehouse'
import {
  getBlibliData,
  getBlibliRequestData,
  getBlibliPickupPoint,
  getMappingStatus,
  authorizeBlibli,
  mappingBlibliPickupPoint,
} from '@/api/channels/blibli'
import { rulesSyncBlibli, rulesWarehouseBlibli } from '@/data/rules'
import data from '@/services/store/data'
// import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'ModalSyncBlibli',
  // components: {
  //   ConsoleLink,
  // },
  props: {
    visible: Boolean,
    back: Function,
    openModal: Function,
    openModalSuccess: Function,
    idData: Number,
  },
  data() {
    return {
      rules: rulesSyncBlibli,
      rulesWarhouse: rulesWarehouseBlibli,
      apiCount: 0,
      blibliCode: '',
      form: {
        id: '',
        api: '',
        confirm: true,
      },
      formChannel: {
        warehouses: [],
      },
      showModalForm: false,
      showModalWarehouse: false,
      showModalSuccess: false,
      loadingBlibli: false,
      loadingSync: false,
      showSuccessIntegrate: false,
      warehouseList: [],
    }
  },
  computed: {
    detailData() {
      return data.find((val) => val.id === this.idData)
    },
    integration() {
      return this.$store.state.integration
    },
  },
  watch: {
    visible(value) {
      this.showModalForm = value
      if(value) this.init()
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    handleCopy(type, val) {
      navigator.clipboard.writeText(val)
      this.$notification.success({
        message: `Copied ${type}: ${val}`,
      })
    },
    refreshPage() {
      window.location.reload()
    },
    showNotification(type, message) {
      this.$notification[type]({
        message: type === 'success' ? 'Berhasil' : 'Gagal',
        description: message,
      })
    },
    async init() {
      this.blibliCode = ''
      this.form = {
        id: '',
        api: '',
        confirm: true,
      }
      this.formChannel.warehouses = []
      await Promise.all([this.fetchWarehouseList(), this.fetchBlibliData()])
    },
    async fetchWarehouseList() {
      await getWarehouseList({
        business_id: this.$route.query.business_id,
        warehouse_id: this.$route.query.warehouse_id,
      })
      .then(({ data: { data: warehouses } }) => this.warehouseList = warehouses.map(warehouse => ({
        value: warehouse.id,
        label: warehouse.name,
      })))
      .catch(() => this.warehouseList = [])
    },
    async fetchBlibliData() {
      this.loadingBlibli = true
      await getBlibliData(this.$route.query.business_id)
        .then(({ data: { data: response } }) => this.blibliCode = response.client_id)
        .catch(err => {
          this.showNotification('error', err.response?.data?.message || err.message)
          this.blibliCode = ''
        })
        .finally(() => this.loadingBlibli = false)
    },
    handleAuthorize() {
      this.$refs.formBlibli.validate(async valid => {
        if (valid) {
          this.loadingSync = true
          await authorizeBlibli({
            business_id: this.$route.query.business_id,
            data: {
              store_id: this.form.id,
              seller_key: this.form.api,
              auto_accept: this.form.confirm,
            },
          })
          .then(({ data }) => {
            if(data?.status?.toLowerCase() === 'success') {
              const { store_id, channel_id } = data?.data
              this.showModalForm = false
              this.showModalWarehouse = true
              this.fetchBlibliPickupPoint(store_id, channel_id)
            } else if(['fail', 'failed', 'error'].includes(data?.status?.toLowerCase())) {
              this.loadingSync = false
              this.showNotification('error', data?.message || 'Integrasi Blibli Gagal')
            } else {
              this.fetchBlibliRequestData(data?.request_id)
            }
          })
          .catch(err => {
            this.loadingSync = false
            this.showNotification('error', err.response?.data?.message || err.message)
          })
        }
      })
    },
    fetchBlibliRequestData: debounce(async function(request_id) {
      if(this.apiCount < 10) {
        await getBlibliRequestData({
            business_id: this.$route.query.business_id,
            request_id,
            params: {
              store_id: this.form.id,
            },
          })
          .then(({ data }) => {
            if(['fail', 'failed', 'error'].includes(data?.status?.toLowerCase())) {
              this.loadingSync = false
              this.apiCount = 0
              this.showNotification('error', 'Integrasi Blibli Gagal')
            } else if(data?.status?.toLowerCase() === 'success') {
              const { store_id, channel_id } = data?.data
              this.showModalForm = false
              this.showModalWarehouse = true
              this.apiCount = 0
              this.fetchBlibliPickupPoint(store_id, channel_id)
            } else {
              this.apiCount++
              this.fetchBlibliRequestData(request_id)
            }
          })
          .catch(err => {
            this.loadingSync = false
            this.apiCount = 0
            this.showNotification('error', err.response?.data?.message || err.message)
          })
      } else {
        this.loadingSync = false
        this.apiCount = 0
        this.showNotification('error', 'Integrasi Blibli Gagal')
      }
    }, 1500),
    async fetchBlibliPickupPoint(store_id, channel_id) {
      await getBlibliPickupPoint({
        business_id: this.$route.query.business_id,
        channel_id,
      })
      .then(({ data }) => {
        this.formChannel.warehouses = data?.data?.map(warehouse => ({
          store_id,
          channel_id,
          pickup_point_id: warehouse.id,
          name: warehouse.name,
          display_name: warehouse.display_name,
          warehouse_id: undefined,
        }))
        this.showModalForm = false
        this.showModalWarehouse = true
      })
      .catch(err => this.showNotification('error', err.response?.data?.message || err.message))
      .finally(() => this.loadingSync = false)
    },
    async handleMapWarehouse() {
      this.$refs.formWarehouse.validate(async valid => {
        if (valid) {
          this.loadingSync = true
          let promises = []

          this.formChannel.warehouses.forEach(warehouse => {
            const { store_id, channel_id, pickup_point_id, warehouse_id } = warehouse
            promises.push(mappingBlibliPickupPoint({
              business_id: this.$route.query.business_id,
              data: {
                store_id,
                channel_id,
                pickup_point_id,
                warehouse_id,
              },
            }))
          })
          
          await Promise.all(promises)
            .then(response => {
              let successNum = 0
              response.forEach((res, index) => {
                if(res?.data?.status?.toLowerCase() === 'success') {
                  successNum++
                } else {
                  this.showNotification('error', 
                    `Integrasi Gudang ${this.formChannel.warehouses[index].name} - ${this.formChannel.warehouses[index].display_name} Gagal`,
                  )
                }
              })
              
              if(successNum) {
                this.showModalWarehouse = false
                this.showModalSuccess = true
              }
            })
            .catch(err => this.showNotification('error', err.response?.data?.message || err.message))
            .finally(() => this.loadingSync = false)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.text-step {
  display: block;
  font-size: 13px;
  font-weight: bold;
  margin: 15px 0 5px -15px;
  color: #2196F3;
}
.text-li {
  text-align: justify;
  color: #5d5e5f;
  font-size: 13px;

  i {
    font-size: 13px !important;
    font-weight: bold !important;
    color: #5d5e5f !important;

    &.anticon-loading {
      color: #FF0A54 !important;
    }
  }
}
.modal-blibli {
  // .ant-modal-body {
  //   padding: 20px !important;
  // }

  .ant-btn {
    i {
      font-size: 16px !important;
      font-weight: bold !important;
      color: #ffffff !important;
    }
  }

  .ant-input-suffix i {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.45) !important;

    &:hover {
      color: rgba(0, 0, 0, 0.9) !important
    }
}
}
</style>
<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
.form-disclaimer {
  color: var(--kit-color-primary);
  font-style: italic !important;
}

.warehouse-blibli {
  &-header {
    padding: 12px 0;
    font-weight: 600;
    background: #e9ecef;
  }

  &-list {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e4e9f0;
  }
}
</style>