<template>
  <a-modal
    :visible="visible"
    :wrap-class-name="$style.modalSync"
    :closable="false"
    :width="showSuccessIntegrate ? 400 : 800"
  >
    <template v-if="showSuccessIntegrate">
      <img :src="require(`@/assets/store/add-store/success-add.svg`)" style="width:60px !important; height:60px !important;" />
      <h4 class="mt-4">
        Berhasil!
      </h4>
      <div class="mt-4 w-100 text-center">
        Proses Integrasi Toko Tokopedia anda sedang dalam proses, silahkan cek email anda.
      </div>
    </template>
    <template v-else>
      <img :src="require(`@/assets/store/add-store/tokopedia.svg`)" />
      <div class="mt-2 pbz-font body-sm-medium form-disclaimer">
        Form ini merupakan formulir untuk pengajuan proses integrasi tokopedia
      </div>
      <h5 class="text-left w-100 mt-4">
        Lengkapi detail informasi berikut
      </h5>
      <a-form-model
        id="form-integrate"
        ref="ruleForm"
        :model="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        class="w-100"
        @submit.prevent="handleSubmitForm(detailData?.id, detailData?.name)"
      >
        <div class="mt-2 w-100">
          <a-form-model-item
            prop="store_name"
            label="Nama Toko"
            :rules="[{ required: true, message: 'Please input your Nama Toko!' }]"
          >
            <a-input
              v-model="form.store_name"
              class="w-100"
              style="width:100%;"
              placeholder="Masukan nama toko"
              required
            />
          </a-form-model-item>

          <a-form-model-item
            prop="store_url"
            label="Domain Toko"
            :rules="[{ required: true, message: 'Please input your Domain Toko' }]"
          >
            <a-input
              v-model="form.store_url"
              class="w-100"
              addon-before="https://www.tokopedia.com/"
              placeholder=". . ."
              required
              @keypress="onChangeStoreUrl"
              @blur="onBlurStoreUrl"
            />
          </a-form-model-item>
        </div>
      </a-form-model>
      <h6 class="text-left w-100 mt-2" style="font-size: 12px">
        Ikuti langkah berikut ini untuk melakukan Integrasi Tokopedia :
      </h6>
      <ol class="pl-3 text-left">
        <li v-for="(item, index) in stepIntegrationTokopedia" :key="index" class="text-desc" style="text-align: justify; color: #6C757D; font-size: 12px">
          {{ item }}
        </li>
      </ol>
    </template>
    <template #footer>
      <template v-if="showSuccessIntegrate">
        <a-button
          class="mr-2"
          @click.prevent="showSuccessIntegrate = false"
        >
          Tambah Toko Lainnya
        </a-button>
        <ConsoleLink :to="{ name: 'store.list' }">
          <a-button
            type="primary"
            :loading="integration.loadingSync"
          >
            Selesai
          </a-button>
        </ConsoleLink>
      </template>
      <template v-else>
        <a-button
          class="mr-2"
          @click="typeof back !== 'undefined' && back(), openModal(null)"
        >
          Kembali
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          form="form-integrate"
          :loading="integration.loadingSync"
        >
          Request Integrasi
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import data from '@/services/store/data'
import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'

export default {
  name: 'ModalSyncWithForm',
  components: {
    ConsoleLink,
  },
  props: {
    visible: Boolean,
    back: Function,
    openModal: Function,
    openModalSuccess: Function,
    idData: Number,
  },
  data: function () {
    return {
      form: {
        store_name: '',
        store_url: '',
      },
      showSuccessIntegrate: false,
      stepIntegrationTokopedia: [
        'Lakukan Permintaan integrasi toko dengan menginput Nama dan Domain Toko Anda. Toko Anda akan berstatus “Menunggu” (Menunggu konfirmasi dari Penjual). Nama dan domain toko Anda dapat dilihat pada web seller center Tokopedia > Pengaturan Toko > Informasi. Lalu copy informasi toko pada bagian Domain Toko (cukup salin tulisan yang setelah "www.tokopedia.com/")',
        'Tokopedia akan mengirimkan email konfirmasi persetujutan integrasi toko ke alamat email toko Anda. Anda juga dapat menerima persetujuan integrasi toko melalui web seller center Tokopedia > Pengaturan Aplikasi Pihak Ketiga > Menunggu persetujuan.',
        'Lalu tunggu proses verifikasi data oleh Powerbiz selama ± 1x24 jam. Proses verifikasi selesai, ditandai dengan status toko Anda berubah menjadi “Pending Warehouse” pada Daftar Toko. Powerbiz juga akan mengirimkan notifikasi ke alamat email Anda, menginformasikan bahwa proses verifikasi sudah selesai.',
        'Lakukan pemetaan gudang yang Anda miliki di Powerbiz dengan gudang Tokopedia melalui menu daftar toko.',
        'Toko Anda siap dioperasikan melalui Powerbiz.',
      ],
    }
  },
  computed: {
    detailData() {
      return data.find((val) => val.id === this.idData)
    },
    integration() {
      return this.$store.state.integration
    },
  },
  methods: {
    onChangeStoreUrl(event) {
      const keyCode = (event.which) ? event.which : event.keyCode
      if(keyCode == 47 || keyCode == 63) {
        event.preventDefault()
      }
    },
    onBlurStoreUrl(event) {
      let value = event.target.value
      const replacedValue = value.replace('https://www.tokopedia.com/', '')
      this.form.store_url = replacedValue.includes('?')
        ? replacedValue.split('?')[0]
        : value.includes('/')
        ? value.replaceAll('/', '')
        : replacedValue
    },
    onClickSubmit() {
      this.$refs['form-integrate'].submit()
    },
    handleSubmitForm(id, name) {
      if (id !== null) {
        if (name === 'Tokopedia') {
          this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            const payload = {
              channelCode: this.detailData?.channelCode,
              store_type: 'OFFICIAL_STORE',
              business: this.$route.query.business_id,
              ...this.form,
              store_url: `https://www.tokopedia.com/${this.form.store_url}`,
            }
            this.$store.dispatch('integration/INTEGRATIONSALESCHANNEL', payload)
              .then((data) => {
                if (data?.channelId && data?.status?.toUpperCase() === 'WAITING') {
                  this.showSuccessIntegrate = true
                  // comment for later integration
                  // this.$store.dispatch('integration/CONFIRMATIONINTEGRATIONSALESCHANNEL', {
                  //   channelCode: this.detailData?.channelCode,
                  //   business: this.$route.query.business_id,
                  //   channelId: data?.channelId,
                  //   aggregator_id: 4,
                  //   shop_id: 14840464,
                  //   warehouse_id: '',
                  // })
                  // .then((data) => {
                  //   if (data?.channelId && data?.status?.toUpperCase() === 'CONNECTED') {
                  //     this.showSuccessIntegrate = true
                  //   }
                  // })
                }
              })
              .catch((err) => {
                this.$notification.error({
                  message: 'Connected Failed',
                  description: err.response?.data?.message || err.message,
                })
              })
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
.form-disclaimer {
  color: var(--kit-color-primary);
  font-style: italic !important;
}
</style>
