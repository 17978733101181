var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.integration.modalIntegrationAddWarehouse,"wrap-class-name":_vm.$style.modalSync,"closable":false,"width":700},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.showSuccessIntegrate)?[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onCloseSuccess}},[_vm._v(" OK ")])]:[_c('a-button',{staticClass:"mr-2",on:{"click":_vm.onClose}},[_vm._v(" Kembali ")]),_c('a-button',{attrs:{"type":"primary","form":"form-integrate","disabled":!(_vm.form.warehouseMaster[0] !== null && _vm.form.warehouseChannel[0] !== null),"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();_vm.loading = true, _vm.handleSubmitForm()}}},[_vm._v(" Submit ")])]]},proxy:true}])},[(_vm.showSuccessIntegrate)?[_c('img',{staticStyle:{"width":"60px !important","height":"60px !important"},attrs:{"src":require(`@/assets/store/add-store/success-add.svg`)}}),_c('h4',{staticClass:"mt-4"},[_vm._v(" Otorisasi Gudang Berhasil! ")]),_c('div',{staticClass:"mt-4 w-100 text-center"},[_vm._v(" Berhasil menambahkan gudang utama dan gudang channel. ")])]:[_c('h5',{staticClass:"text-center w-100 mt-4"},[_vm._v(" Warehouse ")]),_c('a-form-model',{ref:"ruleForm",staticClass:"w-100",attrs:{"model":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},_vm._l((_vm.form.warehouseMaster),function(formWarehouse,index){return _c('a-card',{key:formWarehouse+index,staticClass:"w-100 mt-2"},[(index > 0)?_c('div',{staticClass:"position-absolute top-0 right-0 mt-1 mr-2 close-icon",on:{"click":() => _vm.onRemoveSelectedWarehouse(index)}},[_c('CloseIcon',{attrs:{"color":'black'}})],1):_vm._e(),(_vm.$route.query.code === 'tiktok')?_c('a-form-model-item',{attrs:{"label":_vm.$t('add_store.select_shop'),"rules":[{
            required: true,
            trigger: 'change',
          }]}},[_c('a-select',{attrs:{"show-arrow":true,"show-search":"","filter-option":_vm.filterOption},scopedSlots:_vm._u([{key:"suffixIcon",fn:function(){return [_vm._v(" > ")]},proxy:true}],null,true),model:{value:(_vm.form.selectedShop[index]),callback:function ($$v) {_vm.$set(_vm.form.selectedShop, index, $$v)},expression:"form.selectedShop[index]"}},_vm._l((_vm.integration?.listShop?.authorized_shops),function(item,index){return _c('a-select-option',{key:item.shop_id+index,attrs:{"value":item.shop_id,"title":item.shop_id}},[_vm._v(" "+_vm._s(item.region)+" - "+_vm._s(item.shop_name)+" ")])}),1)],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":'Warehouse Utama',"rules":[{
            required: true,
            trigger: 'change',
          }]}},[_c('a-select',{attrs:{"show-arrow":true,"show-search":"","filter-option":_vm.filterOption},scopedSlots:_vm._u([{key:"suffixIcon",fn:function(){return [_vm._v(" > ")]},proxy:true}],null,true),model:{value:(_vm.form.warehouseMaster[index]),callback:function ($$v) {_vm.$set(_vm.form.warehouseMaster, index, $$v)},expression:"form.warehouseMaster[index]"}},_vm._l((_vm.listWarehouseMaster),function(opt,index){return _c('a-select-option',{key:opt.value+index,attrs:{"value":opt.value,"title":opt.label}},[_vm._v(" "+_vm._s(opt.label)+" ")])}),1)],1),_c('a-form-model-item',{staticClass:"mb-0",attrs:{"label":'Warehouse Channel',"rules":{
            required: true,
            trigger: 'change',
          }}},[_c('a-select',{attrs:{"show-arrow":true,"show-search":"","filter-option":_vm.filterOption},scopedSlots:_vm._u([{key:"suffixIcon",fn:function(){return [_vm._v(" > ")]},proxy:true}],null,true),model:{value:(_vm.form.warehouseChannel[index]),callback:function ($$v) {_vm.$set(_vm.form.warehouseChannel, index, $$v)},expression:"form.warehouseChannel[index]"}},[_vm._l((_vm.listWarehouseChannel),function(opt,index){return _c('a-select-option',{key:opt.value+index,attrs:{"value":opt.value,"title":opt.label}},[_vm._v(" "+_vm._s(opt.label)+" ")])}),_c('template',{slot:"notFoundContent"},[_c('a-empty',{attrs:{"image":_vm.simpleImage}}),_c('div',{staticClass:"d-flex justify-content-center btn",staticStyle:{"padding":"4px 8px","cursor":"pointer"},on:{"mousedown":e => e.preventDefault(),"click":function($event){return _vm.getWarehouseChannelList(_vm.$route.query.channel_id)}}},[_vm._v(" refresh ")])],1)],2)],1)],1)}),1),(((_vm.form?.warehouseMaster[_vm.form?.warehouseMaster?.length - 1] && _vm.form?.warehouseChannel[_vm.form?.warehouseChannel?.length - 1]) && (_vm.form?.warehouseChannel?.length < _vm.listWarehouseChannel?.length && _vm.form?.warehouseMaster?.length < _vm.listWarehouseMaster?.length)))?_c('a-button',{staticClass:"d-flex align-items-center w-100 justify-content-center mt-3",attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onAddWarehouse.apply(null, arguments)}}},[_c('PlusIcon',{staticClass:"mr-2",attrs:{"color":'white'}}),_vm._v(" Tambah Gudang Lain ")],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }