<template>
  <a-modal
    :visible="integration.modalAddShop"
    :wrap-class-name="$style.modalAddShop"
    :closable="false"
  >
    <img
      v-if="detailData?.logo"
      class="mb-5"
      :src="require(`@/assets/store/add-store/${detailData?.logo}`)"
    />
    <!-- If data shop is not null -->
    <template v-if="integration.listShop !== null && !isLoading">
      <h4 class="h6 text-left mb-4">
        {{ $t('add_store.complite_detail_information') }}
      </h4>

      <div v-if="errorAddShop || errorMessage" class="alert alert-danger w-100" role="alert">
        {{
          $store.state.integration.message ? $store.state.integration.message
          : errorMessage ? errorMessage : $t('add_store.error_sync')
        }}
      </div>

      <p class="mb-2">
        {{ $t('add_store.select_shop') }}
      </p>
      <a-select
        ref="select"
        v-model="shop_id_selected"
        style="width: 100%"
        @change="selectShop"
      >
        <a-select-option
          v-for="item in integration?.listShop?.authorized_shops"
          :key="item.shop_id"
          :value="item.shop_id"
        >
          {{ item.region }} - {{ item.shop_name }}
        </a-select-option>
      </a-select>
      <div v-if="warehouseList.length > 0 && $store.state.user.restriction_base !== 'Warehouse'" style="width: 100%">
        <p class="mt-4 mb-2">
          {{ $t('productAvailability.gudang') }}
        </p>
        <a-select
          style="width: 100%"
          :placeholder="$t('productAvailability.gudang')"
          :value="valueWarehouse || undefined"
          @change="handleChangeWarehouse"
        >
          <a-select-option
            v-for="item in warehouseList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
    </template>
    <!-- If data shop is null -->
    <p
      v-if="integration.listShop === null && !isLoading"
      class="text-danger"
    >
      {{ $t('add_store.error_sync') }}
    </p>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center w-100"
    >
      <a-icon type="loading" :spin="true" />
    </div>

    <!-- Footer -->
    <template #footer>
      <a-button v-if="!isLoading" class="mr-2 ant-btn" :disabled="loadingAddShop" @click="closeModal()">
        {{ $t('add_store.back') }}
      </a-button>
      <a-button
        v-if="integration.listShop !== null && !isLoading"
        type="primary"
        :disabled="typeof shop_id_selected === 'undefined' || !valueWarehouse"
        :loading="loadingAddShop"
        @click="addShop"
      >
        {{ $t('add_store.next') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import data from '@/services/store/data'
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'ModalShopAddShop',
  mixins: [getQueryParam],
  props: {
    idData: Number,
  },
  data() {
    return {
      shop_id_selected: null,
      shop_selected: {},
      loadingAddShop: false,
      errorAddShop: false,
      valueWarehouse: null,
      warehouseList: [],
      errorMessage: null,
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.integration.loadingSync
    },
    detailData() {
      return data.find((val) => val.id === this.idData)
    },
    integration() {
      return this.$store.state.integration
    },
  },
  mounted() {
    this.getWarehouseList()
  },
  updated() {
    if (this.shop_id_selected === null) {
      const data = this.$store.state.integration?.listShop?.authorized_shops?.[0]
      this.shop_id_selected = data?.shop_id
      this.shop_selected = data
    }
  },
  methods: {
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    closeModal() {
      this.$store.dispatch('integration/CANCELSTOREMARKETPLACE')
      this.$router.replace(this.to(this.$route.path))
    },
    selectShop(value) {
      const shop = this.$store.state.integration?.listShop?.authorized_shops?.find(
        (val) => val.shop_id === value,
      )
      this.shop_id_selected = value
      this.shop_selected = shop
    },
    async addShop() {
      if (!this.shop_id_selected) {
        this.errorMessage = 'Toko harus dipilih'
        setTimeout(() => {
          this.errorMessage = null
        }, 3000)
        return
      }

      if (!this.valueWarehouse) {
        this.errorMessage = 'Gudang harus dipilih'
        setTimeout(() => {
          this.errorMessage = null
        }, 3000)
        return
      }

      this.loadingAddShop = true
      const data = await this.$store.dispatch('integration/ADDSHOP', {
        business_id: this.$route.query.business_id,
        data: { ...this.shop_selected, warehouse_id: this.valueWarehouse },
      })

      if (typeof data !== 'undefined' && data?.status === 202) {
        this.loadingAddShop = true
      } else {
        this.loadingAddShop = false
        this.errorAddShop = true
        setTimeout(() => {
          this.errorAddShop = false
        }, 3000)
      }
    },
    handleChangeWarehouse(value) {
      this.valueWarehouse = value
      // this.warehouse_selected = value
    },
    async getWarehouseList() {
      return await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
        })
        .then((response) => {
          this.warehouseList = response
        })
        .catch((err) => {
          console.error(err)
          this.warehouseList = []
        })
    },
    async syncMarketPlace(id, name) {
      if (id !== null) {
        let route = {
          path: this.$route.path,
          query: this.$route.query,
        }

        /* Sync tiktok */
        if (name === 'Tiktok') {
          route = {
            ...route,
            query: {
              ...route.query,
              'company-aggregator-id': id,
              name: name.toLowerCase(),
            },
          }
          localStorage.setItem('pathCallback', JSON.stringify(route))
          const { data } = await this.$store.dispatch('integration/SYNCTIKTOK', id)
          window.location = data.data.authorized_url
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
